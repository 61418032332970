import React from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";

import { Form, Container, Row, Button } from "react-bootstrap";
import FormField from "./FormField";
import Layout, { LayoutTopSpacer } from "./Layout";

export default function RequestInvoiceBookingInput(props) {
  const { handleSubmit, errors, control } = useForm();
  const intl = useIntl();
  async function onSubmit(data) {
    props.history.push(`/request-invoice/${data.bookingsync_booking_id}`);
  }

  return (
    <Layout
      title="Checkout | Invoice"
      isLoggedIn={false}
      renderLocaleSwitcher={true}
    >
      <LayoutTopSpacer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Row className="text-center pb-3">
              <h2>
                <FormattedMessage id="input-booking-id-header" />
              </h2>
            </Row>
            <Row className="justify-content-center">
              <FormField
                label="BookingSync booking ID"
                name="bookingsync_booking_id"
                rules={{
                  required: intl.formatMessage({ id: "field-required-error" }),
                  pattern: {
                    value: /^\d+$/,
                    message: intl.formatMessage(
                      { id: "only-numbers-error" },
                      { fieldname: "ID" }
                    ),
                  },
                }}
                control={control}
                errors={errors}
              />
            </Row>
            <Row className="justify-content-center pt-3 mb-3">
              <Button className="mtb-3 w-50" type="submit">
                <FormattedMessage id="confirm-button-text" />
              </Button>
            </Row>
          </Container>
        </Form>
      </LayoutTopSpacer>
    </Layout>
  );
}
