import React from "react";
import { Search } from "./Search";
import { SelectFilter } from "./SelectFilter";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "react-bootstrap";

const ApartmentsFilters = ({
  params,
  data,
  onFilterChange,
  showImportModal,
  onExport,
  resetFilters,
}) => {
  const intl = useIntl();

  if (!data || data.length === 0) {
    return null;
  }

  const {
    filters: { cities, amenities, guests },
    actions,
  } = data;

  const searchRentals = (values) => {
    onFilterChange("search", values !== "" ? values : null);
  };

  const resetSearch = () => {
    onFilterChange("search", null);
  };

  const selectCities = (values) => {
    onFilterChange("cities", values);
  };

  const resetCity = () => {
    onFilterChange("cities", cities[0]);
  };

  const selectGuest = (values) => {
    onFilterChange("guests", values);
  };

  const resetGuest = () => {
    onFilterChange("guests", null);
  };

  const selectAmenities = (values) => {
    onFilterChange("amenities", values);
  };

  const resetAmenities = () => {
    onFilterChange("amenities", null);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 5,
        height: 50,
        fontSize: 14,
      }}
    >
      <>
        <Button onClick={resetFilters} style={{ maxHeight: 38 }}>
          <FormattedMessage id="reset" />
        </Button>
        <Search
          onSubmit={(v) => searchRentals(v)}
          defaultValue={params.getValues("search")}
          placeholder={intl.formatMessage({ id: "rental-name" })}
        />
        <Button
          onClick={resetSearch}
          style={{
            maxHeight: 38,
            width: 38,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <i className="bi bi-x-lg" />
        </Button>
        <SelectFilter
          title={
            params.getValues("cities").length > 0
              ? params.getValues("cities").join(", ")
              : intl.formatMessage({ id: "city" })
          }
          placeholder={intl.formatMessage({ id: "city" })}
          options={cities}
          selected={params.getValues("cities")}
          onSubmit={selectCities}
          onReset={resetCity}
          single
        />
        <SelectFilter
          title={
            params.getValues("guests").length > 0
              ? `${intl.formatMessage({ id: "guests" })}: ${
                  params.getValues("guests")[0]
                }+`
              : intl.formatMessage({ id: "guests" })
          }
          placeholder={intl.formatMessage({ id: "guests" })}
          options={
            guests !== null
              ? [...Array(guests + 1).keys()].map((item) => String(item))
              : []
          }
          selected={params.getValues("guests")}
          onSubmit={selectGuest}
          onReset={resetGuest}
          onDisplay={(v) => `${v}+`}
          single
        />
        <SelectFilter
          title={
            params.getValues("amenities").length > 0
              ? `${intl.formatMessage({ id: "other" })}: ${
                  params.getValues("amenities").length
                }`
              : intl.formatMessage({ id: "other" })
          }
          placeholder={intl.formatMessage({ id: "other" })}
          options={amenities}
          selected={params.getValues("amenities")}
          onSubmit={selectAmenities}
          onReset={resetAmenities}
          onDisplay={(v) => intl.formatMessage({ id: v })}
        />
        {actions && actions.import ? (
          <Button
            style={{
              maxHeight: 38,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={() => showImportModal(true)}
          >
            <FormattedMessage id="import" />
          </Button>
        ) : null}
        {actions && actions.export ? (
          <Button
            style={{
              maxHeight: 38,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={onExport}
          >
            <FormattedMessage id="export" />
          </Button>
        ) : null}
      </>
    </div>
  );
};

export default ApartmentsFilters;
