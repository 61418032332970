import React from "react";
import { daterange } from "./utils";

function CalendarLabels({ fromDate, endDate }) {
  const calendarLabels = daterange(fromDate, endDate);
  return (
    <>
      {calendarLabels &&
        calendarLabels.map((label) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minWidth: 30,
              height: 30,
              // borderRight: "1px solid black",
              // borderBottom: "1px solid black",
              //   marginRight: 5,
              //   padding: 5,
              //   border: "1px solid black",
              //   borderRadius: 25,
              borderBottom: "1px solid #e3e6ec",
              borderRight: "1px solid #e3e6ec",
            }}
            key={`${label.month()}-${label.date()}`}
          >
            {label.date()}
          </div>
        ))}
    </>
  );
}

export default CalendarLabels;
