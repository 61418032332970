import React from "react";
import { FormattedMessage } from "react-intl";

import { Row, Col, Container } from "react-bootstrap";
import { TickIcon, ErrorIcon, WarningIcon } from "./RequestInvoiceIcons.js";

export function SuccessMessage() {
  return (
    <Container>
      <Row className="pt-5 align-items-center justify-content-center">
        <TickIcon />
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center h4">
            <FormattedMessage id="request-invoice-success-line-1" />
            <br />
            <FormattedMessage id="request-invoice-success-line-2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function ErrorMessage() {
  return (
    <Container>
      <Row className="pt-5 align-items-center justify-content-center">
        <ErrorIcon />
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center h4">
            <FormattedMessage id="request-invoice-error-line-1" />
            <br />
            <FormattedMessage id="request-invoice-error-line-2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function MissingBookingErrorMessage() {
  return (
    <Container>
      <Row className="pt-5 align-items-center justify-content-center">
        <ErrorIcon />
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center h4">
            <FormattedMessage id="request-invoice-missing-booking-error-line-1" />
            <br />
            <FormattedMessage id="request-invoice-error-line-2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function MissingGuestErrorMessage() {
  return (
    <Container>
      <Row className="pt-5 align-items-center justify-content-center">
        <ErrorIcon />
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center h4">
            <FormattedMessage id="request-invoice-missing-booking-error-line-1" />
            <br />
            <FormattedMessage id="request-invoice-error-line-2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function WarningMessage() {
  return (
    <Container>
      <Row className="pt-5 align-items-center justify-content-center">
        <WarningIcon />
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center h4">
            <FormattedMessage id="request-invoice-warning-line-1" />
            <br />
            <FormattedMessage id="request-invoice-warning-line-2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function ExpiredMessage() {
  return (
    <Container>
      <Row className="pt-5 align-items-center justify-content-center">
        <WarningIcon />
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center h4">
            <FormattedMessage id="request-invoice-expired-line-1" />
            <br />
            <FormattedMessage id="request-invoice-expired-line-2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
