import React, { useState, useEffect, useMemo, useRef } from "react";
import Layout from "./Layout";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Input from "antd/es/input";

import PMSCalendar from "./PMSCalendar";
import PeriodSelect from "./PeriodSelect";
import { Period } from "./utils";

const CALENDAR_PAGE_SIZE = 30;
const CALENDAR_ROW_HEIGHT = 30;

const { REACT_APP_API_URL: API_URL } = process.env;
const axios = require("axios").default;

const dayjs = require("dayjs");

dayjs().format();

export default function PMS() {
  const { yearFilter: pathYearFilter, monthFilter: pathMonthFilter } =
    useParams();
  const history = useHistory();
  const scroller = useRef();
  const { control, handleSubmit } = useForm();

  const period = useMemo(
    () => Period.fromPath(pathMonthFilter, pathYearFilter, dayjs()),
    [pathMonthFilter, pathYearFilter]
  );

  const [calendarData, setCalendarData] = useState([]);
  const [rentalSearch, setRentalSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const getPage = (search, reset) => {
    const page = calendarData.length / CALENDAR_PAGE_SIZE + 1;
    if (!loading && Number.isInteger(page)) {
      getCalendarData(period, page, search, reset);
    }
  };

  const searchRentals = (v) => {
    setRentalSearch(v);
    getPage(v, true);
  };

  const getCalendarData = async (period, page, search, reset) => {
    setLoading(true);
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/calendar?period=${period.toString()}&search=${search}&page=${page}&page_size=${CALENDAR_PAGE_SIZE}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .then((response) => {
        if (response && response.data) {
          if (reset) {
            setCalendarData([...response.data.data]);
          } else {
            setCalendarData([...calendarData, ...response.data.data]);
          }
        }
      })
      .catch((error) => console.error(error));
    setLoading(false);
  };

  useEffect(() => {
    getCalendarData(period, 1, rentalSearch, true);
  }, [period]);

  if (!pathMonthFilter || !pathYearFilter) {
    return <Redirect to={`/pms/${period.toString()}`} />;
  }

  const runScroller = (v) => {
    if (
      calendarData.length >= CALENDAR_PAGE_SIZE &&
      v.target.scrollTop >
        (calendarData.length - 25) * CALENDAR_ROW_HEIGHT * 0.5
    ) {
      getPage(rentalSearch, false);
    }
  };

  return (
    <Layout
      filters={
        <div
          id="filters"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 85,
            overflow: "auto",
          }}
        >
          {/* <form onSubmit={handleSubmit((v) => searchRentals(v.name))}>
            <Controller
              as={
                <Input
                  style={{
                    border: "1px solid #d9d9d9",
                    minWidth: 150,
                    width: 150,
                  }}
                />
              }
              name="name"
              control={control}
              defaultValue={rentalSearch}
              placeholder="Nazwa apartamentu"
            />
          </form> */}
          <PeriodSelect
            period={period}
            onSelect={(period) => history.push(`/pms/${period.toString()}`)}
          />
        </div>
      }
      nav={[
        <div onClick={() => history.push(`/dashboard/${period.toString()}`)}>
          Dashboard
        </div>,
      ]}
    >
      <div
        id="calendarScroll"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
        onScroll={runScroller}
        ref={scroller}
      >
        <PMSCalendar
          calendarData={calendarData}
          fromDate={dayjs()
            .month(period.month.id - 1)
            .year(period.year)
            .startOf("month")
            .subtract(1, "week")}
          endDate={dayjs()
            .month(period.month.id - 1)
            .year(period.year)
            .endOf("month")
            .add(2, "week")}
          period={period}
          getData={(diff) => getPage(diff)}
        />
      </div>
    </Layout>
  );
}
