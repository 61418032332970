import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { Container, Button, Table, Form } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import Layout, { LayoutTopSpacer } from "./Layout";
import { CompanyIcon, PersonIcon } from "./RequestInvoiceIcons.js";
import {
  MissingBookingErrorMessage,
  MissingGuestErrorMessage,
  SuccessMessage,
  ErrorMessage,
  ExpiredMessage,
} from "./RequestInvoiceMessages";
import "./request-invoice.css";

const { REACT_APP_API_URL: API_URL } = process.env;
const axios = require("axios").default;
const dayjs = require("dayjs");

function getInvoiceTypeDivStyle() {
  return {
    border: "3px solid grey",
    padding: 10,
    display: "flex",
    margin: 10,
    fontSize: "18px",
    height: "170px",
    width: "170px",
    borderRadius: "25px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
}

function InvoiceTypeSelectButtons(props) {
  const history = useHistory();
  return (
    <>
      <Button
        id="invoice-select-btn"
        onClick={() => {
          history.push(`${props.bookingsync_booking_id}/company`);
        }}
        variant="outline-info"
        style={getInvoiceTypeDivStyle()}
      >
        <CompanyIcon />
        <b>
          <FormattedMessage id="invoice-type-company" />
        </b>
      </Button>
      <Button
        id="invoice-select-btn"
        onClick={() => {
          history.push(`${props.bookingsync_booking_id}/person`);
        }}
        variant="outline-info"
        style={getInvoiceTypeDivStyle()}
      >
        <PersonIcon />
        <b>
          <FormattedMessage id="invoice-type-personal" />
        </b>
      </Button>
    </>
  );
}

function BookingSummary({ booking }) {
  return (
    <>
      <p className="h4 my-3 text-center" style={{ color: "#231F20" }}>
        <FormattedMessage id="booking-summary-header" />
      </p>
      <Container className="px-0">
        <Table size="sm" hover>
          <tbody>
            <tr>
              <td>
                <b>
                  <FormattedMessage id="rental-name-field" />
                </b>
              </td>
              <td>{booking.rental.name}</td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage id="arrival" />
                </b>
              </td>
              <td>{dayjs(booking.from_date).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage id="departure" />
                </b>
              </td>
              <td>{dayjs(booking.until_date).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage id="gross-booking-value" />
                </b>
              </td>
              <td>{`${Number(booking.gross_booking_value).toFixed(2)} ${
                booking.currency_code
              }`}</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
}

function GuestSummary({ guest }) {
  const intl = useIntl();
  return (
    <Container className="px-0 py-2">
      <Table size="sm" hover>
        <tbody>
          {guest.masked_vat_number ? (
            <>
              <tr>
                <td>
                  <b>
                    <FormattedMessage id="nip-field" />
                  </b>
                </td>
                <td>{guest.masked_vat_number}</td>
              </tr>
              <tr>
                <td>
                  <b>
                    <FormattedMessage id="company-name-field" />
                  </b>
                </td>
                <td>{guest.masked_company}</td>
              </tr>
            </>
          ) : (
            <tr>
              <td>
                <b>
                  <FormattedMessage id="fullname-field" />
                </b>
              </td>
              <td>{guest.masked_company}</td>
            </tr>
          )}
          <tr>
            <td>
              <b>
                <FormattedMessage id="email-field" />
              </b>
            </td>
            <td>{guest.masked_email}</td>
          </tr>
          <tr>
            <td>
              <b>
                <FormattedMessage id="notes-field" />
              </b>
            </td>
            <td>
              <Form.Control
                id="notes-input"
                as="textarea"
                placeholder={intl.formatMessage({ id: "notes-field" })}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default function RequestInvoiceTypeSelect(props) {
  const DisplayType = {
    SUCCESS: "success",
    ERROR: "error",
    CONFIRM: "confirm",
    SELECT: "select",
    LOADING: "loading",
  };

  const { bookingsync_booking_id } = useParams();
  const [booking, setBooking] = useState(null);
  const [guest, setGuest] = useState(null);
  const [displayType, setDisplayType] = useState(DisplayType.LOADING);
  const [messageType, setMessageType] = useState(null);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    const getBookingData = async () => {
      await axios(
        {
          method: "get",
          url: `${API_URL}/costs/booking/public?bookingsync_booking_id=${bookingsync_booking_id}`,
        },
        []
      )
        .then(
          (response) => response && response.data && setBooking(response.data)
        )
        .catch((error) => setDisplayType(DisplayType.ERROR));

      await axios(
        {
          method: "get",
          url: `${API_URL}/guests/guest/public/by_booking/${bookingsync_booking_id}`,
        },
        []
      )
        .then(
          (response) => response && response.data && setGuest(response.data)
        )
        .catch((error) => {
          setDisplayType(DisplayType.ERROR);
          if (
            error.response &&
            error.response.data &&
            error.response.data.code === "expired"
          ) {
            setMessageType("expired");
          } else {
            setMessageType("error");
          }
        });
    };

    setAgree(false);
    getBookingData();
  }, [bookingsync_booking_id]);

  useEffect(() => {
    booking !== null &&
      guest !== null &&
      (guest.has_invoice_data
        ? setDisplayType(DisplayType.CONFIRM)
        : setDisplayType(DisplayType.SELECT));
  }, [booking, guest]);

  function confirmButtonOnClick() {
    setDisplayType(DisplayType.LOADING);

    const payload = {
      invoice_notes: document.getElementById("notes-input").value || null,
    };

    axios(
      {
        method: "patch",
        url: `${API_URL}/guests/request-invoice/${bookingsync_booking_id}`,
        data: payload,
      },
      []
    )
      .then((response) => {
        setDisplayType(DisplayType.SUCCESS);
      })
      .catch((error) => {
        setDisplayType(DisplayType.ERROR);
      });
  }

  return (
    <Layout
      title="Checkout | Invoice"
      isLoggedIn={false}
      renderLocaleSwitcher={true}
    >
      <LayoutTopSpacer>
        <>
          {displayType === DisplayType.SELECT && (
            <Container className="px-0" id="select-invoice-container">
              <p className="h3 mb-3 text-center" style={{ color: "#231F20" }}>
                <FormattedMessage id="select-invoice-type" />
              </p>
              <Container className="d-flex justify-content-center">
                <InvoiceTypeSelectButtons
                  bookingsync_booking_id={bookingsync_booking_id}
                />
              </Container>
              <BookingSummary booking={booking} />
            </Container>
          )}
          {displayType === DisplayType.CONFIRM && (
            <Container className="px-0" id="select-invoice-container">
              <p className="h3 my-3 text-center" style={{ color: "#231F20" }}>
                <FormattedMessage id="confirm-invoice-data-title" />
              </p>
              <p className="text-center">
                <FormattedMessage id="confirm-invoice-data-subtitle" />
              </p>
              <GuestSummary guest={guest} />
              <div className="form-check d-flex justify-content-center">
                <input
                  className="form-check-input me-1"
                  style={{ minWidth: "1em" }}
                  type="checkbox"
                  value=""
                  onClick={(e) => setAgree(e.target.checked)}
                />
                <label className="form-check-label">
                  <small>
                    <b className="text-danger">*</b>{" "}
                    <FormattedMessage id="confirm-invoice-data-checkbox-text" />
                  </small>
                </label>
              </div>
              <p></p>
              <Container className="d-flex justify-content-center mb-3">
                <Button
                  id="confirm-button"
                  onClick={confirmButtonOnClick}
                  disabled={!agree}
                >
                  <FormattedMessage id="confirm-button-text" />
                </Button>
              </Container>
              <p className="alert alert-warning text-center">
                <small>
                  <FormattedMessage id="confirm-invoice-data-warning" />
                </small>
              </p>
              <BookingSummary booking={booking} />
            </Container>
          )}
          {displayType === DisplayType.SUCCESS && <SuccessMessage />}
          {displayType === DisplayType.ERROR && (
            <>
              {messageType === "error" && (
                <>
                  {booking === null ? (
                    <MissingBookingErrorMessage />
                  ) : guest === null ? (
                    <MissingGuestErrorMessage />
                  ) : (
                    <ErrorMessage />
                  )}
                </>
              )}
              {messageType === "expired" && (
                // expired
                <>
                  <ExpiredMessage />
                </>
              )}
            </>
          )}
        </>
      </LayoutTopSpacer>
    </Layout>
  );
}
