import React from "react";
import { sumByKey } from "./utils";

import Table from "antd/es/table";
import Empty from "antd/es/empty";

const { Column } = Table;

const dayjs = require("dayjs");

dayjs().format();

const COLUMN_TO_TEXT_MAPPING = {
  from_date: "Check-In",
  until_date: "Check-Out",
  days: "Liczba dni pobytu w miesiącu",
  people: "Liczba gości",
  gross: "Wartość pobytu brutto w miesiącu",
  net: "Wartość pobytu netto w miesiącu",
  payment_processing_fee: "PPF",
  commission: "Prowizja",
  extras_sum: "Wartość dodatkowych usług",
  owners: "Przychód właściciela netto",
  comments: "Komentarz",
  extras_description: "Dodatkowe usługi",
  vat: "VAT",
  portal: "Portal",
};

export default function ReservationsTable({ bookings, columns }) {
  const renderColumn = (key, index) => {
    return (
      <Column
        key={`${key}-${index}`}
        title={COLUMN_TO_TEXT_MAPPING[key]}
        dataIndex={key}
      />
    );
  };

  return bookings &&
    bookings.length !== 0 &&
    columns &&
    columns.length !== 0 ? (
    <>
      <Table
        dataSource={bookings}
        rowKey="id"
        size="small"
        pagination={false}
        summary={(pageData) => {
          let sums = [];
          columns.map((key) => {
            if (["comments", "portal", "extras_description"].includes(key)) {
              sums.push({
                sum: "-",
                key: key,
              });
            } else if (
              key !== "from_date" &&
              key !== "until_date" &&
              key !== "id"
            ) {
              sums.push({
                sum: sumByKey(pageData, key),
                key: key,
              });
            }
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <b>Suma rezerwacji</b>
                </Table.Summary.Cell>
                {sums.map((sum) => (
                  <Table.Summary.Cell key={sum.key}>
                    <b>{sum.sum}</b>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </>
          );
        }}
      >
        {columns
          .filter((column) => column !== "id")
          .map((column, index) => renderColumn(column, index))}
      </Table>
    </>
  ) : (
    <Empty
      className="empty-sign"
      description="Brak rezerwacji dla danego okresu."
    />
  );
}
