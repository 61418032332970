import React from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import CountryCodeSelectOptions from "./CountryCodeSelect";

function FormField({
  label,
  name,
  control,
  errors,
  rules,
  element_type,
  defaultValue,
  ...props
}) {
  return (
    <Form.Group className="mb-2">
      <Form.FloatingLabel
        label={
          <>
            {"required" in rules ? (
              <p>
                {label} <b className="text-danger">*</b>
              </p>
            ) : (
              <p>{label}</p>
            )}
          </>
        }
      >
        <Controller
          render={({ onChange, onBlur, value, name, ref }) => (
            <>
              {element_type === "control" && (
                <Form.Control
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  ref={ref}
                  placeholder={label}
                  isInvalid={!!errors[name]}
                  {...props}
                />
              )}
              {element_type === "select_country" && (
                <Form.Select
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  ref={ref}
                  placeholder={label}
                  htmlSize={1}
                  isInvalid={!!errors[name]}
                  value={value}
                  {...props}
                >
                  <CountryCodeSelectOptions />
                </Form.Select>
              )}
              <Form.Control.Feedback type="invalid">
                {errors[name]?.message}
              </Form.Control.Feedback>
            </>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue}
          placeholder={label}
          rules={rules}
        />
      </Form.FloatingLabel>
    </Form.Group>
  );
}

FormField.defaultProps = {
  element_type: "control",
  defaultValue: "",
  rules: {},
};

export default FormField;
