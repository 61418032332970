import React, { useContext, useState, useEffect } from "react";
import { IntlContext, LOCALES } from "./IntlUtils";
import defaultCountriesTranslation from "./compiled-messages/countries_pl.json";

function loadCountriesTranslations(locale_code) {
  const locale_exists = (element) => element.code === locale_code;
  if (LOCALES.some(locale_exists)) {
    return require(`./compiled-messages/countries_${locale_code}.json`);
  }
  return defaultCountriesTranslation;
}

export default function CountryCodeSelectOptions() {
  const context = useContext(IntlContext);
  const [countries, setCountries] = useState(defaultCountriesTranslation);

  useEffect(() => {
    setCountries(loadCountriesTranslations(context.locale));
  }, [context]);

  return (
    <>
      {Object.keys(countries.translations).map((country_code, index) => {
        return (
          <option key={index} value={country_code}>
            {countries.translations[country_code]}
          </option>
        );
      })}
    </>
  );
}
