const monthFilters = [
  {
    month: "Styczeń",
    id: 1,
    formatted: "01",
  },
  {
    month: "Luty",
    id: 2,
    formatted: "02",
  },
  {
    month: "Marzec",
    id: 3,
    formatted: "03",
  },
  {
    month: "Kwiecień",
    id: 4,
    formatted: "04",
  },
  {
    month: "Maj",
    id: 5,
    formatted: "05",
  },
  {
    month: "Czerwiec",
    id: 6,
    formatted: "06",
  },
  {
    month: "Lipiec",
    id: 7,
    formatted: "07",
  },
  {
    month: "Sierpień",
    id: 8,
    formatted: "08",
  },
  {
    month: "Wrzesień",
    id: 9,
    formatted: "09",
  },
  {
    month: "Październik",
    id: 10,
    formatted: "10",
  },
  {
    month: "Listopad",
    id: 11,
    formatted: "11",
  },
  {
    month: "Grudzień",
    id: 12,
    formatted: "12",
  },
];

const quarterFilters = [
  {
    month: "Q1",
    id: 1,
    formatted: "Q1",
  },
  {
    month: "Q2",
    id: 2,
    formatted: "Q2",
  },
  {
    month: "Q3",
    id: 3,
    formatted: "Q3",
  },
  {
    month: "Q4",
    id: 4,
    formatted: "Q4",
  },
];

export { monthFilters, quarterFilters };
