import React from "react";
import Empty from "antd/es/empty";
import Descriptions from "antd/es/descriptions";

export default function RentalInfo({ rental }) {
  return rental && rental.status ? (
    <div style={{ maxWidth: 750 }}>
      <br />
      <Descriptions
        title={`Dane apartamentu ${rental.name}`}
        layout="horizontal"
        column={1}
        bordered
      >
        {rental.status && (
          <Descriptions.Item label="Status">{rental.status}</Descriptions.Item>
        )}
      </Descriptions>
    </div>
  ) : (
    <Empty className="empty-sign" description="Brak danych apartamentów." />
  );
}
