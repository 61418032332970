import React, { useState } from "react";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useIntl, FormattedMessage } from "react-intl";

import Layout, { LayoutTopSpacer } from "./Layout";
import FormField from "./FormField";
import {
  SuccessMessage,
  ErrorMessage,
  WarningMessage,
  ExpiredMessage,
} from "./RequestInvoiceMessages";

import { emailPatternValidator, nipPatternValidator } from "./utils";

const { REACT_APP_API_URL: API_URL } = process.env;
const axios = require("axios").default;

function CompanyFormFields({ control, errors }) {
  const intl = useIntl();
  return (
    <>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "nip-field" })}
            name="vat_number"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
              pattern: {
                value: nipPatternValidator,
                message: intl.formatMessage({ id: "nip-pattern-error" }),
              },
            }}
            control={control}
            errors={errors}
          />
        </Col>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "regon-field" })}
            name="regon"
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "company-name-field" })}
            name="company"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "email-field" })}
            name="email"
            id="email"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
              pattern: {
                value: emailPatternValidator,
                message: intl.formatMessage({ id: "email-pattern-error" }),
              },
            }}
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <FormField
            label={intl.formatMessage({ id: "address-field" })}
            name="address"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "zip-code-field" })}
            name="zip_code"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <FormField
            label={intl.formatMessage({ id: "city" })}
            name="city"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "country-field" })}
            name="country_code"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            id="country"
            element_type="select_country"
            control={control}
            errors={errors}
            defaultValue="PL"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "notes-field" })}
            name="invoice_notes"
            as="textarea"
            rows={3}
            className="h-100"
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
    </>
  );
}

function PersonFormFields({ control, errors }) {
  const intl = useIntl();
  return (
    <>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "fullname-field" })}
            name="company"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "email-field" })}
            name="email"
            id="email"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
              pattern: {
                value: emailPatternValidator,
                message: intl.formatMessage({ id: "email-pattern-error" }),
              },
            }}
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <FormField
            label={intl.formatMessage({ id: "address-field" })}
            name="address"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "zip-code-field" })}
            name="zip_code"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <FormField
            label={intl.formatMessage({ id: "city" })}
            name="city"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            control={control}
            errors={errors}
          />
        </Col>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "country-field" })}
            name="country_code"
            id="country"
            rules={{
              required: intl.formatMessage({ id: "field-required-error" }),
            }}
            element_type="select_country"
            control={control}
            errors={errors}
            defaultValue="PL"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormField
            label={intl.formatMessage({ id: "notes-field" })}
            name="invoice_notes"
            as="textarea"
            rows={3}
            className="h-100"
            control={control}
            errors={errors}
          />
        </Col>
      </Row>
    </>
  );
}

function RequestInvoiceFrom({ type, bookingsync_booking_id }) {
  const { handleSubmit, errors, control } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Possible message types are: success, warning, error
  const [messageType, setMessageType] = useState(null);

  async function onSubmit(data) {
    const payload = {
      company: data.company,
      vat_number: data.vat_number,
      regon: data.regon || null,
      invoice_notes: data.invoice_notes || null,
      company_address: {
        address: data.address,
        city: data.city,
        zip_code: data.zip_code,
        country_code: data.country_code || "PL",
      },
      company_email: { email: data.email },
    };

    await axios(
      {
        method: "post",
        url: `${API_URL}/guests/request-invoice/${bookingsync_booking_id}`,
        data: payload,
      },
      []
    )
      .then((response) => {
        setMessageType("success");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code === "integrity_error"
        ) {
          setMessageType("warning");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.code === "expired"
        ) {
          setMessageType("expired");
        } else {
          setMessageType("error");
        }
      });

    setIsSubmitted(true);
  }

  return (
    <>
      {!isSubmitted ? (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Container className="px-0">
              {type === "company" && (
                <CompanyFormFields control={control} errors={errors} />
              )}
              {type === "person" && (
                <PersonFormFields control={control} errors={errors} />
              )}
              <Row className="justify-content-center mb-3">
                <Button className="my-3 w-50" variant="info" type="submit">
                  <FormattedMessage id="confirm-button-text" />
                </Button>
              </Row>
            </Container>
          </Form>
        </>
      ) : (
        <>
          {(() => {
            switch (messageType) {
              case "success":
                return <SuccessMessage />;
              case "error":
                return <ErrorMessage />;
              case "warning":
                return <WarningMessage />;
              case "expired":
                return <ExpiredMessage />;
              default:
                return <ErrorMessage />;
            }
          })()}
        </>
      )}
    </>
  );
}

export default function RequestInvoice(props) {
  const { bookingsync_booking_id, type } = useParams();

  return (
    <Layout
      title="Checkout | Invoice"
      isLoggedIn={false}
      renderLocaleSwitcher={true}
    >
      <LayoutTopSpacer />
      <RequestInvoiceFrom
        type={type}
        bookingsync_booking_id={bookingsync_booking_id}
      />
    </Layout>
  );
}
