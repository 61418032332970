import { round } from "lodash";
import { monthFilters, quarterFilters } from "./constants";
import dayjs from "dayjs";

export function checkRole(storage) {
  return !!(storage.getItem("role") && storage.getItem("role") === "pms");
}

export const sumByKey = (data, key) =>
  round(
    data.reduce((prev, cur) => round(prev + Number(cur[key]), "2"), 0),
    "2"
  ).toFixed(2);

// TODO: Remove and trasfer everything to Period
export function getPrevPeriod(yearFilter, monthFilter) {
  if (monthFilter.id[0] === "Q") {
    if (monthFilter.id === 1) {
      return `${Number(yearFilter) - 1}/${
        quarterFilters.find((month) => month.id === 4).formatted
      }`;
    }
    return `${yearFilter}/${
      quarterFilters.find((month) => month.id === monthFilter.id - 1).formatted
    }`;
  } else {
    if (monthFilter.id === 1) {
      return `${Number(yearFilter) - 1}/${
        monthFilters.find((month) => month.id === 12).formatted
      }`;
    }
    return `${yearFilter}/${
      monthFilters.find((month) => month.id === monthFilter.id - 1).formatted
    }`;
  }
}

export function getNextPeriod(yearFilter, monthFilter) {
  if (monthFilter.id[0] === "Q") {
    if (monthFilter.id === 4) {
      return `${Number(yearFilter) + 1}/${
        quarterFilters.find((month) => month.id === 1).formatted
      }`;
    }
    return `${yearFilter}/${
      quarterFilters.find((month) => month.id === monthFilter.id + 1).formatted
    }`;
  } else {
    if (monthFilter.id === 12) {
      return `${Number(yearFilter) + 1}/${
        monthFilters.find((month) => month.id === 1).formatted
      }`;
    }
    return `${yearFilter}/${
      monthFilters.find((month) => month.id === monthFilter.id + 1).formatted
    }`;
  }
}

export function daterange(startDate, endDate) {
  let range = [];
  let day = startDate;
  while (day <= endDate) {
    range.push(day);
    day = day.add(1, "day");
  }
  return range;
}

export class Period {
  constructor(month, year) {
    this.month = month;
    this.year = year;
  }

  static fromDate(date) {
    return new Period(
      monthFilters.find((m) => m.id === Number(date.month() + 1)),
      date.year()
    );
  }

  static fromString(string) {
    const [year, month] = string.split("/");
    if (month[0] === "Q") {
      return new Period(
        quarterFilters.find((m) => m.formatted === month),
        year
      );
    }
    return new Period(
      monthFilters.find((m) => m.formatted === month),
      year
    );
  }

  static fromPath(pathMonth, pathYear, now) {
    if (pathMonth && pathMonth[0] === "Q") {
      return new Period(
        quarterFilters.find((month) => month.formatted === pathMonth),
        pathYear || now.year()
      );
    }
    return new Period(
      monthFilters.find((month) => month.formatted === pathMonth) ||
        monthFilters.find((month) => month.id - 1 === now.month()),
      pathYear || now.year()
    );
  }

  toDate(type = "start") {
    if (this.month.month[0] === "Q") {
      if (this.month.id === 1) {
        if (type === "start") {
          return dayjs(`${this.year}-01-01`).hour(12);
        }
        return dayjs(`${this.year}-03-31`).hour(12);
      }
      if (this.month.id === 2) {
        if (type === "start") {
          return dayjs(`${this.year}-04-01`).hour(12);
        }
        return dayjs(`${this.year}-06-30`).hour(12);
      }
      if (this.month.id === 3) {
        if (type === "start") {
          return dayjs(`${this.year}-07-01`).hour(12);
        }
        return dayjs(`${this.year}-09-30`).hour(12);
      }
      if (this.month.id === 4) {
        if (type === "start") {
          return dayjs(`${this.year}-10-01`).hour(12);
        }
        return dayjs(`${this.year}-12-31`).hour(12);
      }
    }
    return dayjs(`${this.year}-${this.month.formatted}-01`).hour(12);
  }

  prev() {
    if (this.month.month[0] === "Q") {
      if (this.month.id === 1) {
        return new Period(
          quarterFilters.find((month) => month.id === 4),
          Number(this.year) - 1
        );
      }
      return new Period(
        quarterFilters.find((month) => month.id === this.month.id - 1),
        this.year
      );
    } else {
      if (this.month.id === 1) {
        return new Period(
          monthFilters.find((month) => month.id === 12),
          Number(this.year) - 1
        );
      }
      return new Period(
        monthFilters.find((month) => month.id === this.month.id - 1),
        this.year
      );
    }
  }

  next() {
    if (this.month.month[0] === "Q") {
      if (this.month.id === 4) {
        return new Period(
          quarterFilters.find((month) => month.id === 1),
          Number(this.year) + 1
        );
      }
      return new Period(
        quarterFilters.find((month) => month.id === this.month.id + 1),
        this.year
      );
    } else {
      if (this.month.id === 12) {
        return new Period(
          monthFilters.find((month) => month.id === 1),
          Number(this.year) + 1
        );
      }
      return new Period(
        monthFilters.find((month) => month.id === this.month.id + 1),
        this.year
      );
    }
  }

  toString() {
    return `${this.year}/${this.month.formatted}`;
  }

  toMonth() {
    if (this.month.month[0] !== "Q") {
      return this;
    }
    if (this.month.id === 1) {
      return Period.fromString(`${this.year}/01`);
    }
    if (this.month.id === 2) {
      return Period.fromString(`${this.year}/04`);
    }
    if (this.month.id === 3) {
      return Period.fromString(`${this.year}/07`);
    }
    if (this.month.id === 4) {
      return Period.fromString(`${this.year}/10`);
    }
  }

  toQuarter() {
    if (this.month.month[0] === "Q") {
      return this;
    }
    if (this.month.id >= 1 && this.month.id <= 3) {
      return Period.fromString(`${this.year}/Q1`);
    }
    if (this.month.id >= 4 && this.month.id <= 6) {
      return Period.fromString(`${this.year}/Q2`);
    }
    if (this.month.id >= 7 && this.month.id <= 9) {
      return Period.fromString(`${this.year}/Q3`);
    }
    if (this.month.id >= 10 && this.month.id <= 12) {
      return Period.fromString(`${this.year}/Q4`);
    }
  }

  isQuarterly() {
    return this.month.month[0] === "Q";
  }

  getPeriods() {
    if (this.isQuarterly()) {
      if (this.month.id === 1) {
        return [
          Period.fromString(`${this.year}/01`),
          Period.fromString(`${this.year}/02`),
          Period.fromString(`${this.year}/03`),
        ];
      }
      if (this.month.id === 2) {
        return [
          Period.fromString(`${this.year}/04`),
          Period.fromString(`${this.year}/05`),
          Period.fromString(`${this.year}/06`),
        ];
      }
      if (this.month.id === 3) {
        return [
          Period.fromString(`${this.year}/07`),
          Period.fromString(`${this.year}/08`),
          Period.fromString(`${this.year}/09`),
        ];
      }
      if (this.month.id === 4) {
        return [
          Period.fromString(`${this.year}/10`),
          Period.fromString(`${this.year}/11`),
          Period.fromString(`${this.year}/12`),
        ];
      }
    }
    return [this];
  }
}

export const emailPatternValidator =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const nipPatternValidator = /^[a-zA-Z0-9]+$/;
