import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { default as axios } from "axios";
import { genericError, logOut } from "./Dashboard";
import { Button, Form, Modal } from "react-bootstrap";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { checkRole } from "./utils";
import ApartmentsFilters from "./ApartmentsFilters";
import ApartmentsTable from "./ApartmentsTable";
import "bootstrap-icons/font/bootstrap-icons.css";

const { REACT_APP_API_URL: API_URL } = process.env;

export class QueryParams {
  constructor(params) {
    this.params = params;
  }

  static fromUrl(url) {
    if (!url || url === "") {
      return new QueryParams({});
    }
    return new QueryParams(
      url.split("&").reduce((map, obj) => {
        const [rawKey, rawVal] = obj.split("=");
        const decoded = rawKey + "=" + decodeURIComponent(rawVal);
        const [key, val] = decoded.split("=");
        return (map[key] = val.split(",")), map;
      }, {})
    );
  }

  toUrl() {
    return Object.keys(this.params)
      .map((key) => `${key}=${encodeURIComponent(this.params[key])}`)
      .join("&");
  }

  getValues(key) {
    if (this.params.hasOwnProperty(key)) {
      return this.params[key];
    } else {
      return [];
    }
  }

  setValues(key, values) {
    if (values === null) {
      delete this.params[key];
    } else {
      this.params = {
        ...this.params,
        [key]: values,
      };
    }
  }
}

const initialData = {
  columns: [],
  data: [],
  filters: {
    cities: [],
    amenities: [],
    guests: null,
  },
  actions: [],
};

export default function Apartments() {
  const { query: URLQuery } = useParams();
  const history = useHistory();

  const params = QueryParams.fromUrl(URLQuery);

  const [data, setData] = useState(initialData);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [selected, setSelected] = useState(null);

  const onSelect = (name) => {
    if (name === selected) {
      setSelected(null);
    } else {
      setSelected(name);
    }
  };

  const getRentals = async (query) => {
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/rentals-cs?${query}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .then((response) => {
        if (response && response.data) {
          parseData(response.data);
        }
      })
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      );
  };

  useEffect(() => {
    const getCitites = async () => {
      await axios(
        {
          method: "get",
          url: `${API_URL}/costs/rentals/cities`,
          headers: { Authorization: `Bearer ${localStorage.token}` },
        },
        []
      )
        .then((response) => {
          if (response && response.data && response.data.length > 0) {
            params.setValues("cities", response.data[0]);
            setData((prevState) => {
              return {
                ...prevState,
                filters: {
                  ...prevState.filters,
                  cities: response.data,
                },
              };
            });
          }
        })
        .catch((error) =>
          error.response &&
          error.response.status &&
          error.response.status === 401
            ? logOut()
            : genericError()
        );
    };
    getCitites();
  }, []);

  useEffect(() => {
    const paramsUrl = params.toUrl();
    if (paramsUrl !== "") {
      getRentals(paramsUrl);
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          data: [],
        };
      });
    }
  }, [params.toUrl()]);

  const parseData = (data) => {
    setData(data);
    setColumns(mapColumnsData(data.columns));
  };

  const mapColumnsData = (data) => {
    let mapped = [];
    for (let i = 0; i < data.length; i++) {
      mapped.push({ index: i, name: data[i] });
    }
    return mapped;
  };

  const redirectWithFilters = (key, values) => {
    setSelected(null);
    params.setValues(key, values);
    history.push(`/apartments/${params.toUrl()}`);
  };

  const onImportSubmit = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    await axios(
      {
        method: "post",
        url: `${API_URL}/costs/rentals-cs/import`,
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      },
      []
    )
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      )
      .finally(() => setShowModal(false));
  };

  const onExport = async () => {
    await axios(
      {
        method: "post",
        url: `${API_URL}/costs/rentals-cs/export?view=${params.getValues(
          "view"
        )}`,
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        responseType: "blob",
      },
      []
    )
      .then((response) => {
        let file = null;
        if (response && response.data) {
          file = new Blob([response.data], {
            type: response.headers["content-type"],
          });
        }
        if (file) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = `CustomerSeriveExport_${new Date().getTime()}.xlsx`;
          link.click();
        }
      })
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? logOut()
          : genericError()
      );
  };

  const resetFilters = () => {
    const view = params.getValues("view");
    setSelected(null);
    history.push(`/apartments/view=${view}&cities=${data.filters.cities[0]}`);
  };

  if (data.filters.cities.length > 0 && params.toUrl() === "") {
    return (
      <Redirect
        to={`/apartments/view=customer_service&cities=${data.filters.cities[0]}`}
      />
    );
  }

  return (
    <Layout
      filters={
        <div
          style={{
            position: "absolute",
            paddingTop: 20,
            paddingBottom: 20,
            minHeight: 50,
          }}
        >
          <ApartmentsFilters
            params={params}
            data={data}
            onFilterChange={redirectWithFilters}
            showImportModal={setShowModal}
            onExport={onExport}
            resetFilters={resetFilters}
          />
        </div>
      }
      nav={
        checkRole(localStorage)
          ? [
              <div
                onClick={() => redirectWithFilters("view", "customer_service")}
              >
                Customer Service
              </div>,
              <div onClick={() => redirectWithFilters("view", "operations")}>
                Operations
              </div>,
              <div onClick={() => history.push("/dashboard")}>Portal</div>,
            ]
          : null
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "auto",
          marginTop: 75,
        }}
      >
        <ApartmentsTable
          view={params.getValues("view")}
          data={data}
          columns={columns}
          selected={selected}
          onSelect={onSelect}
        />
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="import" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>
              <FormattedMessage id="import-file-formats" />
            </Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImportFile(e.target.files[0])}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onImportSubmit(importFile)}>
            <FormattedMessage id="import" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
