import React from "react";

export default function BookingGridItem({
  booking,
  onClick,
  showBorder,
  keyPrefix,
  index,
}) {
  function getBackgroundColor(status) {
    switch (status) {
      case "booked":
        return "#67bceb";
      case "unavailable":
        return "#8890ac";
      case "tentative":
        return "#e8b658";
      default:
        return "#edeff4";
    }
  }
  return (
    <div
      role="button"
      className={`${
        booking && booking.status ? "detail-button  div-button" : ""
      }`}
      key={`${keyPrefix}-${booking && booking.bookingsync_booking_id}-${index}`}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 15,
        height: 30,
        background: getBackgroundColor(booking ? booking.status : null),
        borderBottom: "1px solid #e3e6ec",
        borderRight: showBorder ? "1px solid #e3e6ec" : "",
      }}
      onClick={booking && booking.status ? onClick : () => {}}
    />
  );
}
