import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const renderData = (data) => {
  switch (typeof data) {
    case "boolean":
      return data ? (
        <i
          className="bi bi-check-square"
          style={{ fontSize: 14, color: "green" }}
        />
      ) : (
        <i className="bi bi-x-square" style={{ fontSize: 14, color: "red" }} />
      );
    default:
      return <span>{data}</span>;
  }
};

const ApartmentsRow = React.memo(
  ({ view, data, columns, rentalName, selected, onSelect }) => {
    const backgroundColor = selected ? "rgba(49, 108, 244, 0.1)" : "white";
    return (
      <tr
        style={{
          backgroundColor,
        }}
        key={rentalName}
        onClick={() => onSelect(rentalName)}
      >
        {data.map((data, index) => {
          return (
            <OverlayTrigger
              key={`${rentalName}-${index}-${view}-overlay`}
              placement="top"
              delay={{ show: 400, hide: 50 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {columns && columns[index] ? (
                      <FormattedMessage id={columns[index].name} />
                    ) : null}
                    <span>{rentalName}</span>
                  </div>
                </Tooltip>
              )}
            >
              <td key={`${rentalName}-${index}`}>{renderData(data)}</td>
            </OverlayTrigger>
          );
        })}
      </tr>
    );
  },
  (prevProps, nextProps) =>
    prevProps.view === nextProps.view
      ? prevProps.rentalName === nextProps.rentalName &&
        prevProps.selected === nextProps.selected
      : false
);

export default ApartmentsRow;
