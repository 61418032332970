import React from "react";
import Card from "antd/es/card";
import Empty from "antd/es/empty";
import "./calendar.css";

const dayjs = require("dayjs");

dayjs().format();

export const white = "#ffffff";
export const green = "#4aa96c";
export const red = "#f55c47";
export const yellow = "#ffdf6b";
export const gray = "#aaaaaa";

export default function Calendar({ bookings, period, selected }) {
  function resolveBookingType(booking) {
    if (booking.comments.includes("Pobyt właścicielski")) return "owner";
    if (booking.comments.includes("Blokada terminu")) return "blocked";
    return "booking";
  }

  const periods = period.getPeriods();

  function setStyle(row) {
    let background;

    function resolveColor(type) {
      switch (type) {
        case "owner":
          return yellow;
        case "blocked":
          return gray;
        case "booking":
          return red;
        case "available":
          return green;
        default:
          return red;
      }
    }

    if (row.label || row.dayOfMonth === "-") {
      background = white;
    } else if (row.booked) {
      if (row.types[0] || row.types[1]) {
        if (row.isBookingEnd || row.isBookingStart) {
          background = `linear-gradient(150deg, ${resolveColor(
            row.types[0]
          )} 49%, ${white} 50%, ${resolveColor(row.types[1])} 51%)`;
        } else {
          background = `linear-gradient(150deg, ${resolveColor(
            row.types[0]
          )} 50%, ${resolveColor(row.types[1])} 50%)`;
        }
      } else {
        background = red;
      }
    } else {
      background = green;
    }

    return {
      width: "14.285%",
      textAlign: "center",
      background,
    };
  }

  function renderPeriodCalendar(period) {
    const data = [];
    const startDay = dayjs()
      .month(period.month.id - 1)
      .year(period.year)
      .startOf("month")
      .endOf("day");
    const endDay = dayjs()
      .month(period.month.id - 1)
      .year(period.year)
      .endOf("month")
      .endOf("day");

    let day = startDay;

    // push the labels
    data.push(
      { dayOfWeek: 1, dayOfMonth: "Pn", label: true },
      { dayOfWeek: 2, dayOfMonth: "W", label: true },
      { dayOfWeek: 3, dayOfMonth: "Ś", label: true },
      { dayOfWeek: 4, dayOfMonth: "C", label: true },
      { dayOfWeek: 5, dayOfMonth: "Pt", label: true },
      { dayOfWeek: 6, dayOfMonth: "S", label: true },
      { dayOfWeek: 0, dayOfMonth: "N", label: true }
    );
    // fill days until first of the month
    let emptyDay = 1;
    while (emptyDay !== day.day()) {
      data.push({ dayOfWeek: day.day(), dayOfMonth: "-" });
      emptyDay = (emptyDay + 1) % 7;
    }
    // stretch the bookings for all days they take up and fill the gaps
    for (let i = 0; i < bookings.length; i++) {
      while (
        day.format() < dayjs(bookings[i].from_date).endOf("day").format() &&
        day.format() <= endDay.format()
      ) {
        data.push({
          booked: false,
          dayOfWeek: day.day(),
          dayOfMonth: day.date(),
        });
        day = day.add(1, "day");
      }
      while (
        day.format() <= dayjs(bookings[i].until_date).endOf("day").format() &&
        day.format() <= endDay.format()
      ) {
        const isBookingEnd =
          day.format() === dayjs(bookings[i].until_date).endOf("day").format();
        const isBookingStart =
          day.format() === dayjs(bookings[i].from_date).endOf("day").format();
        const isNextBookingStart =
          bookings[i + 1] &&
          day.format() ===
            dayjs(bookings[i + 1].from_date)
              .endOf("day")
              .format();
        data.push({
          booked: true,
          isBookingEnd,
          isBookingStart: isBookingStart || isNextBookingStart,
          types: [
            isBookingEnd || (!isBookingEnd && !isBookingStart)
              ? resolveBookingType(bookings[i])
              : "available",
            isBookingStart || (!isBookingEnd && !isBookingStart)
              ? resolveBookingType(bookings[i])
              : isNextBookingStart
              ? resolveBookingType(bookings[i + 1])
              : "available",
          ],
          dayOfWeek: day.day(),
          dayOfMonth: day.date(),
        });
        day = day.add(1, "day");
      }
    }
    // fill the rest of the month
    while (day.format() <= endDay.format()) {
      data.push({ dayOfMonth: day.date() });
      day = day.add(1, "day");
    }
    // fill the rest of the calendar with dashes
    for (
      let i =
        43 -
        startDay.day() -
        dayjs()
          .month(period.month.id - 1)
          .year(period.year)
          .daysInMonth();
      i > 0;
      i--
    )
      data.push({ dayOfMonth: "-" });
    return (
      <>
        {periods.length > 1 ? (
          <div
            style={{
              textAlign: "center",
              padding: 20,
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {period.toString()}
          </div>
        ) : null}
        <Card>
          {data.map((row, index) => (
            <Card.Grid hoverable={false} style={setStyle(row)} key={index}>
              {["Pn", "W", "Ś", "C", "Pt", "S", "N"].includes(
                row.dayOfMonth
              ) ? (
                <b>{row.dayOfMonth}</b>
              ) : (
                row.dayOfMonth
              )}
            </Card.Grid>
          ))}
        </Card>
      </>
    );
  }

  return selected ? (
    <>
      <div className="legend">
        {[
          { id: 0, color: green, label: "Wolne" },
          { id: 1, color: red, label: "Zarezerwowane" },
          { id: 2, color: gray, label: "Blokada terminu" },
          { id: 3, color: yellow, label: "Pobyt właścicielski" },
        ].map((i) => (
          <div key={i.id} className="legend-item">
            <div
              className="legend-item-color-box"
              style={{
                backgroundColor: i.color,
                height: 20,
                width: 20,
                marginRight: 5,
                border: "1px solid black",
              }}
            />
            <span>{i.label}</span>
          </div>
        ))}
      </div>
      {periods.map((p) => (
        <div key={p.toString()}>{renderPeriodCalendar(p)}</div>
      ))}
    </>
  ) : (
    <Empty className="empty-sign" description="Wybierz nieruchomość" />
  );
}
