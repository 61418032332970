import React from "react";

function RentalDetail({ rental, period }) {
  return (
    <>
      <p>
        Name: <strong>{rental && rental.name}</strong>
      </p>
      <p>
        PMS Name: <strong>{rental && rental.pms_name}</strong>
      </p>
      <p>
        PMS ID: <strong>{rental && rental.bookingsync_rental_id}</strong>
      </p>
      <p>
        Sharepoint ID: <strong>{rental && rental.sharepoint_id}</strong>
      </p>
      <p>
        City: <strong>{rental && rental.city}</strong>
      </p>
      <p>
        Owners take: <strong>{rental && rental.owners_take}</strong>
      </p>
      <p>
        Status: <strong>{rental && rental.status}</strong>
      </p>
      <p>
        Vat taxpayer: <strong>{rental && rental.vat_taxpayer}</strong>
      </p>
      <p>
        Vat rate: <strong>{rental && rental.vat_rate}</strong>
      </p>
      <p>
        Fixed profit: <strong>{rental && rental.fixed_profit}</strong>
      </p>
      <p>
        Minimum profit: <strong>{rental && rental.minimum_profit}</strong>
      </p>
      <p>
        Commission toggle: <strong>{rental && rental.commission_toggle}</strong>
      </p>
      <p>
        Current balance: <strong>{rental && rental.current_balance}</strong>
      </p>
      <p>
        Parking: <strong>{rental && rental.parking}</strong>
      </p>
      <p>
        Origin: <strong>{rental && rental.origin}</strong>
      </p>
      <p>
        Self invoicing: <strong>{rental && rental.self_invoicing}</strong>
      </p>
      <p>
        Default sales vat rate:{" "}
        <strong>{rental && rental.default_sales_vat_rate}</strong>
      </p>
      <p>
        Settlement type: <strong>{rental && rental.settlement_type}</strong>
      </p>
      <p>
        Bedrooms: <strong>{rental && rental.bedrooms}</strong>
      </p>
      <p>
        Bathrooms: <strong>{rental && rental.bathrooms}</strong>
      </p>
      <p>
        Floor: <strong>{rental && rental.floor}</strong>
      </p>
      <p>
        PMS Rental type: <strong>{rental && rental.pms_rental_type}</strong>
      </p>
      <p>
        Sleeps:{" "}
        <strong>
          {rental && rental.sleeps}
          {rental && rental.sleeps_max && `max (${rental.sleeps_max})`}
        </strong>
      </p>
      <p>
        Surface:{" "}
        <strong>
          {rental && rental.surface} {rental && rental.surface_max}
        </strong>
      </p>
      <strong>Links</strong>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <a
          href={`/dashboard/${
            rental && rental.bookingsync_rental_id
          }/${period.toString()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Settlement View
        </a>
      </div>
    </>
  );
}

export default RentalDetail;
