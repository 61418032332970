import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import * as PropTypes from "prop-types";

export function SelectFilter({
  title,
  options,
  selected,
  onSubmit,
  onReset,
  onDisplay,
  single = false,
}) {
  const [show, setShow] = useState(false);
  const [chosen, setChosen] = useState([...selected]);

  const resetChosen = () => setChosen([...selected]);

  const onToggle = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
    resetChosen();
  };

  const onSelect = (e) => {
    e.stopPropagation();
    const value = e.target.getAttribute("value");
    if (single) {
      if (value === chosen[0]) {
        setChosen([""]);
      } else {
        setChosen([value]);
      }
    } else {
      let newChosen = [...chosen];
      const index = chosen.indexOf(value);
      if (index > -1) {
        newChosen.splice(index, 1);
      } else {
        newChosen.push(value);
      }
      setChosen(newChosen);
    }
  };

  return (
    <Dropdown autoClose="false" show={show} onToggle={onToggle}>
      <Dropdown.Toggle id="dropdown">{title}</Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          key="submit"
          as="button"
          onClick={() => onSubmit(single ? chosen[0] : chosen)}
        >
          <strong>Zastosuj</strong>
        </Dropdown.Item>
        {onReset ? (
          <Dropdown.Item key="reset" as="button" onClick={onReset}>
            <strong>Reset</strong>
          </Dropdown.Item>
        ) : null}
        {options &&
          options.map((value, index) => (
            <Dropdown.Item
              as="button"
              style={{
                color: chosen && chosen.includes(value) ? "white" : "black",
                backgroundColor:
                  chosen && chosen.includes(value) ? "gray" : "white",
              }}
              key={index}
              onClick={(e) => onSelect(e)}
              value={value}
            >
              {onDisplay ? onDisplay(value) : value}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

SelectFilter.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  onReset: PropTypes.func,
  onDisplay: PropTypes.func,
  single: PropTypes.bool,
};
