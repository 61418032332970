import React, { useState } from "react";
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import RentalDetail from "./RentalDetail";
import BookingDetail from "./BookingDetail";
import "./utils.css";
import CalendarRow from "./CalendarRow";

const dayjs = require("dayjs");
dayjs().format();

const axios = require("axios").default;
const { REACT_APP_API_URL: API_URL } = process.env;

export default function PMSCalendar({
  calendarData,
  fromDate,
  endDate,
  period,
}) {
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedRental, setSelectedRental] = useState(null);

  const getRental = async (bookingsync_rental_id) => {
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/rental?bookingsync_rental_id=${bookingsync_rental_id}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .then((response) => {
        if (response && response.data) {
          setSelectedRental(response.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const getBooking = async (bookingsync_booking_id) => {
    await axios(
      {
        method: "get",
        url: `${API_URL}/costs/booking?bookingsync_booking_id=${bookingsync_booking_id}`,
        headers: { Authorization: `Bearer ${localStorage.token}` },
      },
      []
    )
      .then((response) => {
        if (response && response.data) {
          setSelectedBooking(response.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const data = [null, ...calendarData];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {data &&
        data.map((row, index) => (
          <div key={index}>
            <CalendarRow
              row={row}
              fromDate={fromDate}
              endDate={endDate}
              selectRental={getRental}
              selectBooking={getBooking}
            />
          </div>
        ))}
      <Modal
        title={`Rental Detail - ${
          (selectedRental && selectedRental.name) || ""
        }`}
        visible={
          !!(selectedRental && selectedRental.name && selectedBooking === null)
        }
        footer={[
          <Button
            key="back"
            onClick={() => {
              setSelectedRental(null);
            }}
          >
            Close
          </Button>,
        ]}
        onCancel={() => {
          setSelectedRental(null);
        }}
      >
        <RentalDetail rental={selectedRental} period={period} />
      </Modal>
      <Modal
        title={`Booking Detail - ${
          (selectedBooking && selectedBooking.bookingsync_booking_id) || ""
        }`}
        visible={!!(selectedBooking && selectedBooking.bookingsync_booking_id)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setSelectedBooking(null);
              setSelectedRental(null);
            }}
          >
            Close
          </Button>,
        ]}
        onCancel={() => {
          setSelectedBooking(null);
          setSelectedRental(null);
        }}
      >
        <BookingDetail booking={selectedBooking} rental={selectedRental} />
      </Modal>
    </div>
  );
}
