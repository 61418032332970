import React from "react";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ApartmentsRow from "./ApartmentsRow";

const ApartmentsTable = ({ view, data, columns, selected, onSelect }) => {
  const extractColumnsData = (rental) => {
    let data = [];
    for (const key in rental) {
      if (rental.hasOwnProperty(key)) {
        data.push(rental[key]);
      }
    }
    return data;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Table
        style={{
          fontSize: 12,
          alignSelf: "flex-start",
        }}
        bordered
        hover
      >
        <thead>
          <tr>
            {columns &&
              columns.map((column) => (
                <th key={column.index}>
                  <FormattedMessage id={`${column.name}`} />
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {columns &&
            columns.length > 0 &&
            data &&
            data.data &&
            data.data.map((rental, index) => {
              const columnsData = extractColumnsData(rental);
              return (
                <ApartmentsRow
                  view={view}
                  key={`${rental.name}-${index}-row`}
                  data={columnsData}
                  columns={columns}
                  rentalName={rental.name}
                  selected={selected === rental.name}
                  onSelect={onSelect}
                />
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ApartmentsTable;
