import React, { useState, useEffect, useContext } from "react";
import { IntlProvider } from "react-intl";
import { Form } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";
import defaultMessages from "./compiled-messages/pl.json";

// To add new locale just add it to LOCALES array and
// make sure that translation file in compile-messages directory
// is present
export const LOCALES = [
  { code: "pl", display: "🇵🇱" },
  { code: "en", display: "🇬🇧" },
];

const defaultLocale = "pl";

function getDefaultLocale() {
  var locale_code = navigator.language.substring(0, 2);

  const locale_exists = (element) => element.code === locale_code;
  if (LOCALES.some(locale_exists)) {
    return locale_code;
  }

  return "en";
}

export const IntlContext = React.createContext();

function loadLocaleData(locale_code) {
  const locale_exists = (element) => element.code === locale_code;
  if (LOCALES.some(locale_exists)) {
    return require(`./compiled-messages/${locale_code}.json`);
  }
  return defaultMessages;
}

export const IntlWrapper = (props) => {
  const [locale, setLocale] = useLocalStorage("locale", getDefaultLocale());
  const [messages, setMessages] = useState(defaultMessages);

  useEffect(() => {
    setMessages(loadLocaleData(locale));
  }, [locale]);

  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
  }

  return (
    <IntlContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export const LocaleSwitcher = () => {
  const context = useContext(IntlContext);
  return (
    <Form.Select
      size="sm"
      style={{ width: "auto" }}
      value={context.locale}
      onChange={context.selectLanguage}
    >
      {LOCALES.map((locale, i) => (
        <option value={locale.code} key={locale.code}>
          {locale.display}
        </option>
      ))}
    </Form.Select>
  );
};
