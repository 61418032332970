import React from "react";
import BookingGridItem from "./BookingGridItem";
import { isEmpty } from "lodash";
import CalendarLabels from "./CalendarLabels";

const CalendarDataRow = React.memo(
  ({ row, selectRental, selectBooking }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      key={row.rental.bookingsync_rental_id}
    >
      <div
        role="button"
        className="detail-button div-button"
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: 180,
          maxWidth: 180,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontSize: 12,
          borderBottom: "1px solid #e3e6ec",
          borderRight: "1px solid #e3e6ec",
        }}
        onClick={() => {
          selectRental(row.rental.bookingsync_rental_id);
        }}
      >
        {row.rental.name}
      </div>
      {row.bookings.map((bookingRow) => {
        const {
          day,
          left_booking: leftBooking,
          right_booking: rightBooking,
          different_booking: differentBooking,
        } = bookingRow;
        return (
          <div
            key={day}
            style={{
              display: "flex",
            }}
          >
            <BookingGridItem
              booking={leftBooking}
              onClick={() => {
                selectBooking(leftBooking.bookingsync_booking_id);
              }}
              showBorder={
                differentBooking ||
                (leftBooking &&
                  leftBooking.bookingsync_booking_id &&
                  isEmpty(rightBooking))
              }
              keyPrefix="left"
              index={day}
            />
            <BookingGridItem
              booking={rightBooking}
              onClick={() => {
                selectBooking(rightBooking.bookingsync_booking_id);
              }}
              showBorder={
                !(rightBooking && rightBooking.bookingsync_booking_id)
              }
              keyPrefix="right"
              index={day}
            />
          </div>
        );
      })}
    </div>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.row.rental.bookingsync_rental_id ===
      nextProps.row.rental.bookingsync_rental_id
    );
  }
);

const CalendarRow = ({ row, fromDate, endDate, selectRental, selectBooking }) =>
  row && row.rental ? (
    <CalendarDataRow
      row={row}
      selectRental={selectRental}
      selectBooking={selectBooking}
    />
  ) : (
    <div
      style={{
        display: "flex",
      }}
      key="labels"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: 180,
          maxWidth: 180,
          borderBottom: "1px solid #e3e6ec",
          borderRight: "1px solid #e3e6ec",
        }}
      />
      <CalendarLabels fromDate={fromDate} endDate={endDate} />
    </div>
  );

export default CalendarRow;
