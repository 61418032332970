import React from "react";

function BookingDetail({ booking }) {
  const { rental } = booking;

  function splitBookingDate(date) {
    return `${date.split("T")[0]} (${date.split("T")[1]})`;
  }

  return (
    <>
      <p>
        Rental: <strong>{rental && rental.name}</strong>
      </p>
      <p>
        Status: <strong>{booking && booking.status}</strong>
      </p>
      <p>
        {booking && splitBookingDate(booking.from_date)}{" "}
        <strong>[{booking && booking.length}]</strong>{" "}
        {booking && splitBookingDate(booking.until_date)}
      </p>
      <p>
        Created by: <strong>{booking && booking.created_by}</strong>
      </p>
      <p>
        Created date: <strong>{booking && booking.created_date}</strong>
      </p>
      <p>
        Source: <strong>{booking && booking.source}</strong>
      </p>
      <p>
        Gross booking value:{" "}
        <strong>{booking && booking.gross_booking_value}</strong>
      </p>
      <p>
        Gross rental value:{" "}
        <strong>{booking && booking.gross_rental_value}</strong>
      </p>
      <p>
        Commission: <strong>{booking && booking.commission}</strong>
      </p>
      <p>
        Origin: <strong>{booking && booking.origin}</strong>
      </p>
      <strong>Links</strong>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <a
          href={booking && booking.admin_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Admin URL
        </a>
        <a
          href={booking && booking.pms_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          PMS URL
        </a>
      </div>
    </>
  );
}

export default BookingDetail;
