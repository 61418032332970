import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Form } from "react-bootstrap";

export function Search({ onSubmit, placeholder }) {
  const [search, setSearch] = useState("");

  return (
    <div
      style={{
        minWidth: 200,
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(search);
        }}
      >
        <Form.Control
          className="mb-3"
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
        />
      </form>
    </div>
  );
}

Search.propTypes = {
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
};
