import React from "react";
import "./dashboard.css";
import { Controller, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Input, Button, List, Dialog } from "antd-mobile";
import Layout from "./Layout";

const { REACT_APP_API_URL: API_URL } = process.env;

const axios = require("axios").default;

export default function ResetPassword() {
  const { resetUUID } = useParams();
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm();

  async function onReset({ password }) {
    await axios(
      {
        method: "post",
        url: `${API_URL}/accounts/change-password`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        data: { resetUUID, password },
      },
      []
    )
      .then(() => {
        Dialog.alert({
          content: "Hasło zostało zmienione.",
          closeOnMaskClick: true,
          confirmText: "Zamknij",
          onConfirm: () => history.push("/login"),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 400) {
            Dialog.alert({
              content: "Hasło nie spełnia podanych wymagań.",
              closeOnMaskClick: true,
              confirmText: "Zamknij",
            });
          } else {
            Dialog.alert({
              content:
                "Wystąpił błąd podczas komunikacji z serwerem. Spróbuj ponownie za kilka minut.",
              closeOnMaskClick: true,
              confirmText: "Zamknij",
            });
          }
        }
      });
  }

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          margin: 10,
          padding: 10,
          borderRadius: 10,
          marginTop: 50,
        }}
      >
        <div>
          <form onSubmit={handleSubmit(onReset)}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Controller
                as={<Input type="password" />}
                style={{
                  background: "white",
                  margin: 10,
                  padding: 10,
                  border: "1px solid #eeeeee",
                  borderRadius: 10,
                }}
                name="password"
                control={control}
                defaultValue=""
                placeholder="Hasło"
                rules={{
                  required: true,
                  minLength: 8,
                }}
              />
              {errors.password &&
                errors.password.type === "required" &&
                "Hasło jest wymagane."}
              {errors.password &&
                errors.password.type === "minLength" &&
                "Hasło musi mieć minimum 8 znaków."}
              <Button type="submit">Zresetuj hasło</Button>
              <div
                style={{
                  background: "white",
                  margin: 10,
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <List>
                  <List.Item style={{ fontSize: 12 }}>
                    Hasło musi mieć minimum 8 znaków.
                  </List.Item>
                  <List.Item style={{ fontSize: 12 }}>
                    Hasło nie może zawierać samych cyfr.
                  </List.Item>
                  <List.Item style={{ fontSize: 12 }}>
                    Hasło nie może być zbyt podobne do loginu, maila lub innych
                    danych konta.
                  </List.Item>
                  <List.Item style={{ fontSize: 12 }}>
                    Proste, popularne hasła nie są akceptowane.
                  </List.Item>
                </List>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
